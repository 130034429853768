
<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title></b-card-title>
                <b-card-text>
                    <iframe style="border-radius:12px" src="https://open.spotify.com/embed/track/6lsEybZd467ZysAh3qfcor?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </b-card-text>
                 <b-link href="#" class="detaillink "></b-link>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Spotify'
    }
</script>

<style>

</style>